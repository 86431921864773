import { memo } from '../../../util/memo';
import Button from '@mui/material/Button';
import { useRegisterDialogs } from './dialogs/useRegisterDialogs';
import { TournamentPanelV3 } from '../TournamentPanelV3';
import { MAX_TOURNAMENT_PANE_WIDTH } from '../TournamentTabs';

const CreateTeamPanelUnmemoized = () => {
  const { open: openRegisterDialog, isOpen } = useRegisterDialogs();
  return (
    <TournamentPanelV3
      title="Create a Team"
      description="Invite your friends to compete with you! Incomplete teams will be filled with
      random solo players and/or merged with other incomplete teams."
      wrapperSx={{ p: 4, maxWidth: MAX_TOURNAMENT_PANE_WIDTH }}
      Content={
        <Button
          onClick={() => {
            if (isOpen) {
              return;
            }
            openRegisterDialog({
              registrationType: 'team',
            });
          }}
          variant="contained"
          sx={{ width: '100%' }}
        >
          CREATE A TEAM
        </Button>
      }
    />
  );
};

export const CreateTeamPanel = memo(CreateTeamPanelUnmemoized);
