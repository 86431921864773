import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Typography from '@mui/material/Typography';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { useDateFormatter } from 'src/hooks/useDateFormatter';
import { memo } from '../../../../util/memo';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import Stack from '@mui/material/Stack';
import { useWizard } from 'src/components/wizard/Wizard';
import { formatDateRange } from '../../../../util/dates/Dates';
import { useAuth } from '../../../../contexts/AuthContext';
import { AlertStandard } from '../../../AlertStandard';
import { useTournamentRegistration } from '../../../../contexts/TournamentRegistrationContext';
import { RegisterStore } from './useRegisterDialogs';
import { DialogActionsStandard } from '../../../dialog/DialogActionsStandard';
import { useRegistrationFunctions } from '../../../../hooks/tournaments/useRegistrationFunctions';
import { useAlertDialog } from '../../../../hooks/useAlertDialog';
import { extractErrorMessage } from '../../../../../functions/src/util/sentinel/extractErrorMessage';
import { NoticeParagraphed } from '../../../NoticeParagraphed';

const CREATOR_ALREADY_REGISTERED = 'CREATOR_ALREADY_REGISTERED';
export type RulesRegisterDialogProps = {
  teamId?: string;
  onSubmit?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  registrationType: string;
};

export const RulesRegisterDialogUnmemoized = ({
  registrationType,
  onCancel,
  onSubmit,
  teamId,
}: RulesRegisterDialogProps) => {
  const { go, get } = useWizard<RegisterStore>();
  const { uid } = useAuth();
  const { open: openCreatorTaken } = useAlertDialog(CREATOR_ALREADY_REGISTERED);
  const {
    phase,
    checkInTime,
    readyTime,
    date,
    rulesAndRegulations,
    skipCheckIn,
  } = useTournamentRegistration();

  const { checkIn, registerSolo, createTeam, acceptTeamInvite } =
    useRegistrationFunctions();

  const details = get('details');

  const checkInWindowFormatted = formatDateRange(checkInTime, readyTime);
  const format = useDateFormatter({
    separator: ', ',
  });
  const startDateFormatted = format(date);

  const submitDetails = async () => {
    try {
      if (!!teamId) {
        await acceptTeamInvite(details, teamId);
        return;
      }
      registrationType === 'solo'
        ? await registerSolo(details)
        : await createTeam(details);
    } catch (error) {
      go(undefined);
      if (extractErrorMessage(error).includes('already part of a team')) {
        openCreatorTaken({
          title: 'Already Registered',
          description:
            'You have already registered for this tournament. Please remove yourself from your existing team or unregister as a solo player.',
        });
      }
    }
  };

  const checkInNow = async () => {
    if (!uid) {
      return;
    }
    await checkIn(false, uid);
  };

  return (
    <DialogBodyStandard
      title={'Tournament Rules'}
      description={
        <Stack spacing={4} alignItems={'center'}>
          <Box
            sx={{
              textAlign: 'center',
              backgroundImage: 'none',
              display: 'inline-block',
              maxWidth: '540px',
            }}
          >
            <Typography variant="h6" sx={{ display: 'inline' }}>
              The tournament begins{' '}
            </Typography>
            <GradientTypography
              variant="h6"
              gradientColor="warning.vertical"
              sx={{ display: 'inline' }}
            >
              {startDateFormatted}
            </GradientTypography>
            <Typography variant="h6" sx={{ display: 'inline' }}>
              . In order to participate, you must agree to the rules below:
            </Typography>
          </Box>

          {rulesAndRegulations && (
            <NoticeParagraphed text={rulesAndRegulations} />
          )}
          {phase !== 'checkIn' && !skipCheckIn && (
            <AlertStandard
              severity="warning"
              textColor="warning.light"
              message={`DO NOT FORGET: The check-in window is ${checkInWindowFormatted}. If you do not check-in, you will NOT be able to participate in the tournament.`}
            />
          )}
        </Stack>
      }
    >
      <DialogActionsStandard
        buttons={[
          {
            children: phase === 'checkIn' ? 'Check In' : 'Accept',
            isAsync: true,
            onClick: async () => {
              await submitDetails();
              if (phase === 'checkIn') {
                await checkInNow();
              }
              if (!!onSubmit) {
                await onSubmit();
              }
              go(undefined);
            },
            color: 'secondary',
            endIcon: <CheckCircleOutlineIcon />,
            sx: { flex: 2 },
          },
          {
            children: 'Cancel',
            isAsync: true,
            onClick: async () => {
              if (!!onCancel) {
                await onCancel();
              }
              return go(undefined);
            },
            sx: { textTransform: 'uppercase', flex: 1, ml: 1 },
            color: 'error',
          },
        ]}
      />
    </DialogBodyStandard>
  );
};

export const RulesRegisterDialog = memo(RulesRegisterDialogUnmemoized);
