import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { memo } from '../util/memo';
import { useState, ReactNode, useMemo, useCallback, useEffect } from 'react';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRightRounded';
import { GradientIconButton } from './gradients/GradientIconButton';
import { GradientTypography } from './gradients/GradientTypography';
import { type SxProps, useTheme } from '@mui/material/styles';
import { useGlobalComponentsContext } from '../contexts/GlobalComponentsContext';
import { FULLSCREEN_CAROUSEL_ID } from '../hooks/messaging/useFullscreenPreview';
import { useKeypressListener } from '../hooks/useKeypressListener';
import isEqual from 'fast-deep-equal/react';

export type ContentCarouselProps = {
  content: ReactNode[];
  sx?: SxProps;
  buttonSx?: SxProps;
  bottom?: number;
  shiftToLatest?: boolean;
};

const ContentCarouselUnmemoized = ({
  content,
  sx,
  buttonSx,
  bottom = 20,
  shiftToLatest = false,
}: ContentCarouselProps) => {
  const { remove } = useGlobalComponentsContext();
  useKeypressListener(['Escape'], () => {
    remove(FULLSCREEN_CAROUSEL_ID);
  });

  const theme = useTheme();

  const [activeStep, setActiveStep] = useState<number>(0);
  const maxSteps = content.length;

  useEffect(() => {
    if (shiftToLatest) {
      setActiveStep(maxSteps - 1);
    }
  }, [maxSteps, shiftToLatest]);

  const changeStep = useCallback(
    (increment: number) => {
      setActiveStep((prevActiveStep) => {
        return Math.max(0, Math.min(maxSteps - 1, prevActiveStep + increment));
      });
    },
    [maxSteps],
  );

  const next = useCallback(() => {
    return changeStep(1);
  }, [changeStep]);

  const prev = useCallback(() => {
    return changeStep(-1);
  }, [changeStep]);

  const navButtonSx = useMemo(() => {
    return {
      width: '40px',
      height: '40px',
      background: theme.palette.other.snackbar,
      borderRadius: '50%',
      p: 1,
      ...buttonSx,
    };
  }, [theme, buttonSx]);

  const currContent = content[Number(activeStep)];

  return (
    <Stack
      direction="column"
      alignItems="center"
      position="relative"
      height="100%"
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          ...sx,
        }}
      >
        {currContent}
      </Box>

      {maxSteps > 1 && (
        <Stack
          direction="row"
          spacing={4}
          alignItems="center"
          position="absolute"
          bottom={bottom}
        >
          <GradientIconButton
            onClick={prev}
            disabled={activeStep === 0}
            IconComponent={KeyboardArrowLeft}
            iconBaseFill={activeStep === 0 ? 'disabled' : undefined}
            sx={navButtonSx}
          />
          <GradientTypography
            variant="subtitle1"
            gradientColor="primary.vertical"
            width="50px"
            textAlign="center"
          >{`${activeStep + 1}/${maxSteps}`}</GradientTypography>
          <GradientIconButton
            onClick={next}
            disabled={activeStep === maxSteps - 1}
            IconComponent={KeyboardArrowRight}
            iconBaseFill={activeStep === maxSteps - 1 ? 'disabled' : undefined}
            sx={navButtonSx}
          />
        </Stack>
      )}
    </Stack>
  );
};

export const ContentCarousel = memo(ContentCarouselUnmemoized, (prev, next) => {
  const { buttonSx: prevButtonSx, ...prevRest } = prev;
  const { buttonSx: nextButtonSx, ...nextRest } = next;
  return isEqual(prevRest, nextRest);
});
