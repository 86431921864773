import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ComponentProps, useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { AlgoliaLayout } from '../../algolia/AlgoliaLayout';
import { FriendCarouselHeader } from './FriendCarouselHeader';
import {
  FriendVerticalCarousel,
  useFriendVerticalCarouselAds,
} from '../../algolia/catalog-wrappers/FriendVerticalCarousel';
import { useAuth } from '../../../contexts/AuthContext';
import { NoFriends } from '../../profile/header/NoFriends';
import { FriendCard } from '../../cards/friend/FriendCard';
import { useTheme } from '@mui/material/styles';
import { fillTemplate } from '../../../../functions/src/util/algoliaRealtime/fillTemplate';
import { REALTIME_CONFIGURATION_TEMPLATES } from '../../../../functions/src/util/algoliaRealtime/templates';
import { useMobile } from '../../../hooks/useMobile';
import { FRIENDS_VIEW_AD } from '../../../../functions/src/util/ads/adIds';
import {
  FRIEND_TABS_HEIGHT_DESKTOP,
  FRIEND_TABS_HEIGHT_MOBILE,
} from './FriendsAdd';

export const FRIENDS_HITS_PER_PAGE = 20 as const;

export type FriendsViewProps = {
  onlineFamCount: number;
  onlineFriendsCount: number;
};

const FriendsViewUnmemoized: React.FC<FriendsViewProps> = ({
  onlineFamCount,
  onlineFriendsCount,
}) => {
  const { uid } = useAuth();
  const theme = useTheme();
  const isMobile = useMobile();

  const FriendVerticalCarouselAds =
    useFriendVerticalCarouselAds(FRIENDS_VIEW_AD);

  const CarouselComponent = useMemo(() => {
    return isMobile ? FriendVerticalCarousel : FriendVerticalCarouselAds;
  }, [FriendVerticalCarouselAds, isMobile]);

  const FamCatalogWrapper = useCallback(
    (
      props: Omit<
        ComponentProps<typeof CarouselComponent>,
        | 'RenderFriendHit'
        | 'Header'
        | 'NoFriends'
        | 'showDivider'
        | 'containerSx'
      >,
    ) => {
      return (
        <CarouselComponent
          RenderFriendHit={FriendCard}
          {...props}
          Header={
            <FriendCarouselHeader title="FAM" onlineCount={onlineFamCount} />
          }
          NoFriends={<NoFriends variant="fam" isSelf={true} />}
          showDivider={false}
          containerSx={{
            background: theme.palette.background.elevation[2],
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onlineFamCount, CarouselComponent],
  );

  const FriendsCatalogWrapper = useCallback(
    (
      props: Omit<
        ComponentProps<typeof CarouselComponent>,
        | 'RenderFriendHit'
        | 'Header'
        | 'NoFriends'
        | 'showDivider'
        | 'containerSx'
      >,
    ) => {
      return (
        <CarouselComponent
          RenderFriendHit={FriendCard}
          {...props}
          Header={
            <FriendCarouselHeader
              title="FRIENDS"
              onlineCount={onlineFriendsCount}
            />
          }
          NoFriends={<NoFriends variant="friends" isSelf={true} />}
          showDivider={false}
          containerSx={{
            background: theme.palette.background.elevation[2],
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onlineFriendsCount, CarouselComponent],
  );

  const friendsFilter = !!uid
    ? fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['friend'], uid)
    : '';
  const famFilter = !!uid
    ? fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['fam'], uid)
    : '';

  const famConfigureOptions = useMemo(() => {
    return {
      hitsPerPage: FRIENDS_HITS_PER_PAGE,
      filters: famFilter,
    };
  }, [famFilter]);
  const friendsConfigureOptions = useMemo(() => {
    return {
      hitsPerPage: FRIENDS_HITS_PER_PAGE,
      filters: friendsFilter,
    };
  }, [friendsFilter]);

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        height: {
          xs: `calc(100% - ${FRIEND_TABS_HEIGHT_MOBILE}px)`,
          md: `calc(100% - ${FRIEND_TABS_HEIGHT_DESKTOP}px)`,
        },
        pt: 2,
      }}
    >
      <Stack
        spacing={6}
        justifyContent={'space-between'}
        sx={{
          px: 4,
          height: '100%',
        }}
      >
        <Box height="50%">
          <AlgoliaLayout
            CatalogWrapper={FamCatalogWrapper}
            configureOptions={famConfigureOptions}
            index="CONTENT"
          />
        </Box>
        <Box height="50%">
          <AlgoliaLayout
            CatalogWrapper={FriendsCatalogWrapper}
            configureOptions={friendsConfigureOptions}
            index="CONTENT"
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export const FriendsView = memo(FriendsViewUnmemoized);
