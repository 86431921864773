import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useState, useCallback, useMemo } from 'react';
import { memo } from '../../util/memo';
import TextField from '@mui/material/TextField';
import { LoadingButton } from 'src/components/buttons/LoadingButton';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { useAuth } from 'src/contexts/AuthContext';
import { MemberListItem } from 'src/components/cards/friend/MemberListItem';
import { AlgoliaLayout } from '../algolia/AlgoliaLayout';
import { UserCardAdd } from '../cards/friend/UserCardAdd';
import { FriendCarouselHeader } from '../social-drawer/friends/FriendCarouselHeader';
import { ChannelGroupUser } from '../../../functions/src/types/firestore/User/ChannelGroup';
import {
  useUserVerticalCarouselAds,
  UserVerticalCarouselProps,
  UserVerticalCarousel,
} from '../algolia/catalog-wrappers/UserVerticalCarousel';
import { useTheme } from '@mui/material/styles';
import { usePersonalChannelGroup } from '../../hooks/messaging/usePersonalChannelGroup';
import { useRouterState } from '../../hooks/routing/useRouterState';
import { AlertStandard } from '../AlertStandard';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import {
  FRIEND_TABS_HEIGHT_DESKTOP,
  FRIEND_TABS_HEIGHT_MOBILE,
} from '../social-drawer/friends/FriendsAdd';
import { withoutUser } from '../../util/algolia/withoutUser';
import { USER_CONFIGURE_OPTIONS } from '../../../functions/src/util/algolia/config/user';
import { CHANNEL_MANAGER_AD } from '../../../functions/src/util/ads/adIds';
import { useMobile } from '../../hooks/useMobile';
import { ChannelMembersProvider } from './ChannelMembersContext';
import { FriendPaneOption } from '../social-drawer/friends/FriendsPane';

export const GROUP_CHAT_MAX = 100 as const;
export const CREATE_BUTTON_HEIGHT = 42 as const;
export const NAME_INPUT_HEIGHT = 154 as const;

export type ChannelManagerProps = {
  initialName?: string;
};

const ChannelManagerUnmemoized = ({ initialName }: ChannelManagerProps) => {
  const { userData } = useAuth();
  const theme = useTheme();
  const [name, setName] = useState(initialName || '');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { set: setPersonalChannelGroup } = usePersonalChannelGroup();
  const { openChannelGroup } = useActiveChannelGroup();
  const [__, setFriendsTab] = useRouterState({
    key: 'friends',
  });

  const initializeMembersWithSelf = useCallback(() => {
    if (!userData) return [];

    return [
      {
        userId: userData.id,
        image: userData.imgUrl,
        username: userData.username,
        isAdmin: true,
      },
    ];
  }, [userData]);

  const membersState = useState<ChannelGroupUser[]>(initializeMembersWithSelf);
  const [members, setMembers] = membersState;

  const createChannelGroup = useCallback(async () => {
    if (members.length > 2 && !name) {
      setErrorMessage('Please add a name.');
      return;
    }
    const memberIds = members.reduce((prev, { userId }) => {
      if (userId !== userData?.id) {
        prev.push(userId);
      }
      return prev;
    }, [] as string[]);

    const { data: channelGroup } = await setPersonalChannelGroup({
      type: members.length === 2 ? 'dm' : 'group',
      friendIds: memberIds,
      title: name,
    });

    setFriendsTab('View' as FriendPaneOption);
    openChannelGroup(channelGroup.id);
  }, [
    members,
    name,
    openChannelGroup,
    setPersonalChannelGroup,
    setFriendsTab,
    userData?.id,
  ]);

  const maxMembersReached = members.length >= GROUP_CHAT_MAX;

  const UserVerticalCarouselAds =
    useUserVerticalCarouselAds(CHANNEL_MANAGER_AD);

  const isMobile = useMobile();
  const AllUserVerticalCarousel = useMemo(() => {
    return withoutUser(
      isMobile ? UserVerticalCarousel : UserVerticalCarouselAds,
    );
  }, [UserVerticalCarouselAds, isMobile]);

  const UserCarouselWrapper = useCallback(
    (
      props: Omit<
        UserVerticalCarouselProps,
        'ContentCard' | 'Header' | 'showDivider'
      >,
    ) => {
      return (
        <AllUserVerticalCarousel
          ContentCard={(userAlgolia) => {
            return (
              <UserCardAdd {...userAlgolia} disabled={maxMembersReached} />
            );
          }}
          {...props}
          Header={
            <FriendCarouselHeader title="ADD USERS" separateSearch={true} />
          }
          showDivider={false}
        />
      );
    },
    [AllUserVerticalCarousel, maxMembersReached],
  );

  const removeMember = useCallback(
    (toRemoveId: string) => {
      setMembers((prev) => {
        return prev.filter(({ userId: memberId }) => {
          return memberId !== toRemoveId;
        });
      });
      setErrorMessage('');
      setName('');
    },
    [setMembers],
  );

  const MembersList = useMemo(() => {
    return members.map((member) => {
      const { userId, image } = member;
      return (
        <MemberListItem
          {...member}
          imgUrl={image}
          key={userId}
          onRemove={removeMember}
        />
      );
    });
  }, [members, removeMember]);

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        height: {
          xs: `calc(100% - ${FRIEND_TABS_HEIGHT_MOBILE}px)`,
          md: `calc(100% - ${FRIEND_TABS_HEIGHT_DESKTOP}px)`,
        },
        p: 4,
        pt: 2,
      }}
    >
      <Stack spacing={6} height={`calc(100% - ${CREATE_BUTTON_HEIGHT}px)`}>
        <Box sx={{ maxHeight: '50%' }}>
          <ChannelMembersProvider membersState={membersState}>
            <AlgoliaLayout
              CatalogWrapper={UserCarouselWrapper}
              configureOptions={USER_CONFIGURE_OPTIONS}
              index="CONTENT"
            />
          </ChannelMembersProvider>
        </Box>
        {maxMembersReached && (
          <AlertStandard
            message={'Maximum number of members reached.'}
            severity="warning"
          />
        )}
        <Stack
          direction="column"
          alignItems="flex-start"
          spacing={2}
          sx={{ maxHeight: `calc(50% - ${NAME_INPUT_HEIGHT}px)` }}
        >
          <GradientTypography variant="h6" gradientColor="primary.vertical">
            MEMBERS
          </GradientTypography>
          <Box
            width="100%"
            sx={{
              overflowY: 'auto',
              ...theme.palette.scrollbars.invisible,
            }}
          >
            {MembersList}
          </Box>
        </Stack>
        {members.length > 2 && (
          <Stack direction="column" alignItems="flex-start" spacing={2}>
            <GradientTypography variant="h6" gradientColor={'primary.vertical'}>
              NAME CHAT
            </GradientTypography>
            <TextField
              fullWidth
              autoFocus
              error={!!errorMessage}
              helperText={errorMessage}
              value={name}
              onChange={(e) => {
                setErrorMessage('');
                setName(e.target.value);
              }}
              sx={{
                '.MuiInputBase-root': { height: `${CREATE_BUTTON_HEIGHT}px` },
              }}
            />
          </Stack>
        )}
      </Stack>
      <LoadingButton
        fullWidth
        onClick={createChannelGroup}
        variant="contained"
        color="primary"
        disabled={members.length < 2 || (members.length > 2 && !name)}
        sx={{ height: `${CREATE_BUTTON_HEIGHT}px` }}
      >
        Create
      </LoadingButton>
    </Stack>
  );
};

export const ChannelManager = memo(ChannelManagerUnmemoized);
