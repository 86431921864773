// TournamentCardSponsors.tsx
import { useEffect, useState, useRef, FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { ImageOptimized } from '../../image/ImageOptimized';
import { SPONSOR_IMG_URL_DEFAULT } from './TournamentCardImagery';
import { memo } from '../../../util/memo';

export const TWO_THOUSAND_MS = 2000 as const;
export const MIN_VISIBILITY = 0.05 as const;

export const SPONSORS_WIDTH = 48 as const;

type Sponsor = {
  imgUrl?: string;
};

export type TournamentCardSponsorsProps = {
  sponsors: Sponsor[];
  height: number;
};

const TournamentCardSponsorsUnmemoized: FC<TournamentCardSponsorsProps> = ({
  sponsors,
  height,
}) => {
  const theme = useTheme();
  const sponsorHeight = sponsors.length > 3 ? height : height / sponsors.length;

  const [currentSponsorIndex, setCurrentSponsorIndex] = useState(0);
  const componentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (sponsors.length < 3 || !componentRef.current) {
      return;
    }

    let interval: NodeJS.Timeout;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio >= MIN_VISIBILITY) {
          interval = setInterval(() => {
            setCurrentSponsorIndex((prevIndex) => {
              return (prevIndex + 1) % sponsors.length;
            });
          }, TWO_THOUSAND_MS);
        } else {
          if (interval) {
            clearInterval(interval);
          }
        }
      },
      { threshold: MIN_VISIBILITY },
    );

    const currentRef = componentRef.current;

    observer.observe(currentRef);

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [sponsors.length]);

  const sponsorElements = useMemo(() => {
    return sponsors.map((sponsor, index) => {
      const sponsorImgUrl = sponsor.imgUrl || SPONSOR_IMG_URL_DEFAULT;
      const isVisible = sponsors.length < 3 || index === currentSponsorIndex;
      return (
        <Box
          key={index}
          sx={{
            height: '100%',
            width: '100%',
            '& img': {
              p: '2px !important',
            },
            alignItems: 'center',
            justifyContent: 'center',
            display: isVisible ? 'flex' : 'none',
            transition: 'opacity 0.5s ease',
          }}
        >
          <ImageOptimized
            layout="fixed"
            height={sponsors.length >= 3 ? height - 4 : sponsorHeight}
            width={SPONSORS_WIDTH}
            objectFit="contain"
            src={sponsorImgUrl}
            style={{ borderRadius: '5px' }}
          />
        </Box>
      );
    });
  }, [currentSponsorIndex, sponsorHeight, sponsors, height]);

  return (
    <Box
      ref={componentRef}
      width={SPONSORS_WIDTH}
      sx={{
        height: '100%',
        background: theme.palette.background.elevation[14],
        borderRadius: '5px',
        flexDirection: 'column',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      {sponsorElements}
    </Box>
  );
};

export const TournamentCardSponsors = memo(TournamentCardSponsorsUnmemoized);
