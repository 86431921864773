import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ReactNode, useMemo } from 'react';
import { memo } from '../util/memo';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../contexts/AuthContext';
import { FriendsPane } from './social-drawer/friends/FriendsPane';
import { SignUpPrompted } from './SignUpPrompted';
import { useRouterState } from '../hooks/routing/useRouterState';
import { ChannelGroupActive } from './messaging/channel/ChannelGroupActive';
import { useSocialDrawer } from '../hooks/useSocialDrawer';
import { useActiveChannelGroup } from '../contexts/ActiveChannelGroupContext';
import {
  APP_NAVIGATION_TAB_HEIGHT,
  APP_NAVIGATION_TAB_HEIGHT_MOBILE,
} from './AppNavigationTabs';
import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_MOBILE } from './Sidebar';
import { MIN_HEADER_HEIGHT } from './header/Header';

export const SOCIAL_DRAWER_WIDTH_DESKTOP = 552 as const;

export type FrameworkProps = {
  Explore: ReactNode;
  Sidebar: ReactNode;
};

const FrameworkUnmemoized = ({ Explore, Sidebar }: FrameworkProps) => {
  const { isSocialDrawerOpen } = useSocialDrawer();
  const theme = useTheme();
  const { uid } = useAuth();
  const { isChannelGroupOpen, channelGroupActive } = useActiveChannelGroup();

  const tabState = useRouterState({
    key: 'nav',
    defaultValue: 'Events',
  });

  const activePane = useMemo(() => {
    return tabState[0];
  }, [tabState]);

  return (
    <Stack
      direction="row"
      width="100%"
      height="100%"
      position="relative"
      justifyContent={{ xs: 'flex-end', md: 'space-between' }}
    >
      <Box
        height="100%"
        sx={{
          width: {
            xs: `calc(100% - ${SIDEBAR_WIDTH_MOBILE}px)`,
            md: isSocialDrawerOpen
              ? `calc(100% - ${SOCIAL_DRAWER_WIDTH_DESKTOP}px)`
              : `calc(100% - ${SIDEBAR_WIDTH}px)`,
          },
          transition: 'width 300ms ease-in-out',
          display: {
            xs: activePane === 'Events' ? 'block' : 'none',
            md: 'block',
          },
        }}
      >
        {Explore}
      </Box>
      <Box
        sx={{
          height: {
            xs: `calc(100% - ${
              MIN_HEADER_HEIGHT + APP_NAVIGATION_TAB_HEIGHT_MOBILE + 1
            }px)`,
            md: '100%',
          },
          zIndex: theme.zIndex.appBar + 1,
          alignSelf: 'flex-end',
        }}
      >
        {Sidebar}
      </Box>

      <Box
        height="100%"
        position="absolute"
        sx={{
          right: {
            xs: `${SIDEBAR_WIDTH_MOBILE}px`,
            md: `${SIDEBAR_WIDTH}px`,
          },
          pt: {
            xs: `${MIN_HEADER_HEIGHT + APP_NAVIGATION_TAB_HEIGHT_MOBILE}px`,
            md: 0,
          },
          background: theme.palette.background.elevationSolid[4],
          zIndex: { xs: theme.zIndex.appBar - 1, md: theme.zIndex.appBar },
          width: {
            xs: `calc(100% - ${SIDEBAR_WIDTH_MOBILE}px)`,
            md: `${SOCIAL_DRAWER_WIDTH_DESKTOP - SIDEBAR_WIDTH}px`,
          },
          transform: {
            xs: !channelGroupActive ? `translateX(100%)` : 'translateX(0)',
            md: `translateX(${
              !channelGroupActive || !isSocialDrawerOpen
                ? `${SOCIAL_DRAWER_WIDTH_DESKTOP}px`
                : 0
            })`,
          },
          transition: 'transform 300ms ease-in-out',
        }}
      >
        {!!channelGroupActive && isChannelGroupOpen && (
          <ChannelGroupActive channelGroup={channelGroupActive} />
        )}
      </Box>

      <Stack
        sx={{
          width: {
            xs: `calc(100% - ${SIDEBAR_WIDTH_MOBILE}px)`,
            md: `${SOCIAL_DRAWER_WIDTH_DESKTOP - SIDEBAR_WIDTH}px`,
          },
          transform: {
            xs: undefined,
            md: isSocialDrawerOpen ? undefined : 'translateX(100%)',
          },
          transition: 'transform 300ms ease-in-out',
          position: 'absolute',
          top: 0,
          left: { xs: 0, md: 'auto' },
          right: { xs: 'auto', md: `${SIDEBAR_WIDTH}px` },
          zIndex: theme.zIndex.appBar - 2,
          boxShadow: isSocialDrawerOpen ? theme.shadows[4] : undefined,
          background: theme.palette.background.elevation[2],
          pt: {
            xs: `${MIN_HEADER_HEIGHT + APP_NAVIGATION_TAB_HEIGHT_MOBILE}px`,
            md: `${APP_NAVIGATION_TAB_HEIGHT}px`,
          },
          height: '100%',
          display: {
            xs: activePane === 'Friends' ? 'block' : 'none',
            md: 'block',
          },
        }}
      >
        {uid !== undefined && (
          <>
            {uid !== null ? (
              <FriendsPane />
            ) : (
              <Box
                height="100%"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <SignUpPrompted prompt={'Please sign up to view friends.'} />
              </Box>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};
export const Framework = memo(FrameworkUnmemoized);
