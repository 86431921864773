import { useCallback, useRef } from 'react';
import { injectAds, InjectAdsProps } from '../../util/ads/injectAds';
import { generateNonAdjacentPositions } from '../../../functions/src/util/generateNonAdjacentPositions';
import { appendAdPositions } from '../../util/ads/appendAdPositions';
import { unshiftAdPositions } from '../../util/ads/unshiftAdPositions';
import { areEqual } from '../../../functions/src/util/algolia/areEqual';
import { didAppendElements } from '../../../functions/src/util/algolia/didAppendElements';
import { OrNode } from '../../../functions/src/types/Hit';
import { stableHash } from '../../../functions/src/util/hash/stableHash';
import { didUnshiftElements } from '../../../functions/src/util/algolia/didUnshiftElements';
import { ResponsiveAdDimensions } from '../../components/ads/AdContainer';

export type UseAdInjectionProps = {
  adFrequency: number;
} & Omit<InjectAdsProps<unknown>, 'elements' | 'adPositions'> &
  ResponsiveAdDimensions;

/**
 * @remark
 * WARNING: This hook uses shared state internally and is not designed to be used
 * with multiple InstantSearch contexts simultaneously. Using it for more than one
 * InstantSearch context may lead to race conditions and unexpected behavior.
 * If you need to use this hook in multiple contexts, consider creating separate
 * instances of the hook for each context or refactoring your components to avoid
 * shared state issues.
 */
export const useAdInjection = <TElement>({
  adFrequency,
  ...injectProps
}: UseAdInjectionProps) => {
  const prevElementsRef = useRef<TElement[]>([]);
  const prevElementsHashesRef = useRef<string[]>([]);
  const prevAdPositionsRef = useRef<Set<number>>(new Set());
  const prevInjectedHitsRef = useRef<OrNode<TElement>[]>([]);

  return useCallback(
    (elements: TElement[]) => {
      const prevElements = prevElementsRef.current;
      const prevElementsHashes = prevElementsHashesRef.current;
      const prevAdPositions = prevAdPositionsRef.current;
      const prevInjectedHits = prevInjectedHitsRef.current;

      const currentElementsHashes = elements.map(stableHash);

      if (areEqual(prevElementsHashes, currentElementsHashes)) {
        return prevInjectedHits;
      }

      const adPositions = (() => {
        if (didUnshiftElements(prevElementsHashes, currentElementsHashes)) {
          return unshiftAdPositions({
            currentAdPositions: prevAdPositions,
            numNewElements: elements.length - prevElements.length,
            adFrequency,
          });
        }

        if (didAppendElements(prevElementsHashes, currentElementsHashes)) {
          return appendAdPositions({
            currentAdPositions: prevAdPositions,
            numNewElements: elements.length - prevElements.length,
            totalElements: elements.length,
            adFrequency,
          });
        }

        return generateNonAdjacentPositions({
          maxIndex: elements.length - 1,
          numEntities: Math.floor(elements.length / adFrequency),
        });
      })();

      const injectedHits = injectAds({
        elements,
        adPositions,
        ...injectProps,
      });

      prevElementsRef.current = elements;
      prevElementsHashesRef.current = currentElementsHashes;
      prevAdPositionsRef.current = adPositions;
      prevInjectedHitsRef.current = injectedHits;

      return injectedHits;
    },
    [injectProps, adFrequency],
  );
};
