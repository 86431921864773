import { RefObject, useMemo, useState, useEffect, useCallback } from 'react';
import { stableHash } from '../../../functions/src/util/hash/stableHash';
import { findAllScrollableAncestors } from '../../util/findAllScrollableAncestors';

export type VisibilityObserverResult = {
  target: HTMLElement | null;
  intersectionRatio: number;
  isIntersecting: boolean;
};

export const DEFAULT_VISIBILITY_RESULT = {
  target: null,
  intersectionRatio: 0,
  isIntersecting: false,
} as const;

export function useVisibilityObserver<
  TElement extends HTMLElement = HTMLElement,
>(
  target: RefObject<TElement | null> | TElement | null,
  options: Omit<IntersectionObserverInit, 'root'> = {},
) {
  const element = useMemo(() => {
    return target instanceof Element ? target : target?.current || null;
  }, [target]);

  const [result, setResult] = useState<VisibilityObserverResult>({
    ...DEFAULT_VISIBILITY_RESULT,
    target: element,
  });

  const aggregateResult = useCallback(
    (intersectionStates: IntersectionObserverEntry[]) => {
      const lowestRatio = Math.min(
        ...intersectionStates.map(({ intersectionRatio }) => {
          return intersectionRatio;
        }),
      );
      const allIntersecting = intersectionStates.every(({ isIntersecting }) => {
        return isIntersecting;
      });

      setResult({
        target: element,
        intersectionRatio: lowestRatio,
        isIntersecting: allIntersecting,
      });
    },
    [element],
  );

  useEffect(() => {
    if (!element) {
      setResult(DEFAULT_VISIBILITY_RESULT);
      return;
    }

    const scrollableAncestors = findAllScrollableAncestors(element);
    const observers: IntersectionObserver[] = [];
    const intersectionStates: IntersectionObserverEntry[] = [];

    scrollableAncestors.forEach((root, index) => {
      const observer = new IntersectionObserver(
        (entries) => {
          const firstEntry = entries[0];
          if (!firstEntry) {
            return;
          }
          intersectionStates[Number(index)] = firstEntry;
          aggregateResult(intersectionStates);
        },
        { ...options, root },
      );

      observer.observe(element);
      observers.push(observer);
    });

    return () => {
      observers.forEach((observer) => {
        return observer.disconnect();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, stableHash(options), aggregateResult]);

  return result;
}
