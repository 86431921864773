import { useEffect } from 'react';
import { track } from '@vercel/analytics';

export type InteractionTrackingData = {
  id: string;
  groupId: string;
  hostname: string;
  browser: string;
  os: string;
  userId: string;
};

export const useClickTracking = <TDataCustom extends Record<string, string>>(
  data: InteractionTrackingData & TDataCustom,
  target: HTMLElement | null,
) => {
  useEffect(() => {
    if (!target) return;

    const trackClick = () => {
      track('click', data);
    };

    target.addEventListener('click', trackClick);

    return () => {
      target.removeEventListener('click', trackClick);
    };
  }, [data, target]);
};
