import {
  createContext,
  useState,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { memo } from '../util/memo';
import { useOnline } from './OnlineContext';
import { useRouter } from '../hooks/routing/useRouter';
import { isAdBlockDetected } from '../util/ads/detectAdBlock';

export const MIN_FAILURE = 2;
export const MAX_HISTORY = 15;
export const AD_DISPLAY_MS = 20000;
export const WINDOW_DURATION_MS = AD_DISPLAY_MS + 5000;
export const CHECK_CONSOLE_INTERVAL_MS = 1000;

export const isAdBlockError = (error: string) => {
  return error.includes('Failed to fetch');
};

export type AdBlockContextProps = {
  spyAd: (errorMessage?: string) => void;
  showAdBlockDialog: boolean;
};

const AdBlockContext = createContext<AdBlockContextProps | undefined>(
  undefined,
);

export type AdBlockProviderProps = {
  children: ReactNode;
};

export const useAdBlock = () => {
  const context = useContext(AdBlockContext);
  if (!context) {
    throw new Error('useAdBlock must be used within an AdBlockProvider');
  }
  return context;
};

const AdBlockProviderUnmemoized: FC<AdBlockProviderProps> = ({ children }) => {
  const [adLoadAttempts, setAdLoadAttempts] = useState<
    { timestamp: number; blocked: boolean }[]
  >([]);

  const isOnline = useOnline();
  const { pathname } = useRouter();
  const isOnContactPage = pathname === '/contact';
  const allowAdBlock = !isOnline || isOnContactPage;

  const [showAdBlockDialog, setShowAdBlockDialog] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        typeof window !== 'undefined' &&
        isAdBlockDetected() &&
        !allowAdBlock
      ) {
        setShowAdBlockDialog(true);
        clearInterval(intervalId);
      }
    }, CHECK_CONSOLE_INTERVAL_MS);

    return () => {
      return clearInterval(intervalId);
    };
  }, [allowAdBlock]);

  const shouldShowAdBlockDialog = useMemo(() => {
    if (adLoadAttempts.length < MIN_FAILURE) {
      return false;
    }

    const windowStart = Date.now() - WINDOW_DURATION_MS;
    const attemptsWithinWindow = adLoadAttempts.filter((attempt) => {
      return attempt.timestamp >= windowStart;
    });

    if (attemptsWithinWindow.length > 0) {
      return attemptsWithinWindow.every((attempt) => {
        return attempt.blocked;
      });
    }
    const recentAttempts = adLoadAttempts.slice(-MIN_FAILURE);
    return recentAttempts.every((attempt) => {
      return attempt.blocked;
    });
  }, [adLoadAttempts]);

  const spyAd = useCallback(
    (errorMessage?: string) => {
      const timestamp = Date.now();
      const blocked = errorMessage ? isAdBlockError(errorMessage) : false;
      const attempt = { timestamp, blocked };

      setAdLoadAttempts((prevAttempts) => {
        const updatedAttempts = [...prevAttempts, attempt];
        if (updatedAttempts.length > MAX_HISTORY) {
          updatedAttempts.shift();
        }
        return updatedAttempts;
      });

      if (shouldShowAdBlockDialog && allowAdBlock) {
        setShowAdBlockDialog(true);
      }
    },
    [allowAdBlock, shouldShowAdBlockDialog],
  );

  const memoizedValue = useMemo(() => {
    return { spyAd, showAdBlockDialog };
  }, [spyAd, showAdBlockDialog]);

  return (
    <AdBlockContext.Provider value={memoizedValue}>
      {children}
    </AdBlockContext.Provider>
  );
};

export const AdBlockProvider = memo(AdBlockProviderUnmemoized);
