// The containerId constants follow the format "location-device-number" or "location-any-number" when the device is not specified.

export const GAME_PAGE_AD = 'game' as const;

export const GAME_CAROUSEL_AD = 'gameCarousel' as const;

export const MESSAGING_AD = 'messaging' as const;

export const PROFILE_PAGE_AD = 'profile' as const;

export const FRIENDS_ADD_AD = 'friendsAdd' as const;
export const FRIENDS_EDIT_AD = 'friendsEdit' as const;
export const FRIENDS_VIEW_AD = 'friendsView' as const;

export const CHANNEL_MANAGER_AD = 'channelManager' as const;

export const BRACKET_AD = 'bracket' as const;

export const OVERVIEW_AD = 'overview' as const;

export const REGISTRATION_AD = 'registration' as const;

export const PARTICIPANTS_AD = 'participants' as const;

export const CALENDAR_AD = 'calendar' as const;
