import React, {
  useMemo,
  FC,
  useEffect,
  useRef,
  useState,
  useCallback,
  type CSSProperties,
} from 'react';
import { memo } from '../../util/memo';
import { SxProps } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import {
  AdDimension,
  AdHeight,
  AdWidth,
} from '../../../functions/src/util/ads/AdDimension';
import { useAdBlock } from '../../contexts/AdBlockContext';
import { sortedHash } from '../../../functions/src/util/hash/sortedHash';
import {
  EngagementTrackingProps,
  useEngagementTracking,
} from '../../hooks/engagement/useEngagementTracking';
import { useMobile } from '../../hooks/useMobile';
import { StopwatchCallbackMap } from '../../hooks/visibility/useVisibilityStopwatch';
import { useParentDimensions } from '../../hooks/useParentDimensions';

export const MOBILE_AD_DISPLAY_MS = 30000 as const;
export const DESKTOP_AD_DISPLAY_MS = 30000 as const;

export type ResponsiveWidth = {
  width: '100%';
  height: AdHeight;
};

export type ResponsiveHeight = {
  width: AdWidth;
  height: '100%';
};

export type ResponsiveAdDimensions =
  | AdDimension
  | ResponsiveWidth
  | ResponsiveHeight;

export type AdSize = {
  adUnitId: string;
  width: number;
  height: number;
};

export type AdContainerProps<TProps extends AdSize> = Pick<
  EngagementTrackingProps,
  'id'
> & {
  width: number | '100%';
  height: number | '100%';
  sx?: SxProps;
  borderRadius?: string;
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  findAdSize: (dimensions: {
    width: number;
    height: number;
  }) => AdSize | undefined;
  AdInsert: FC<TProps>;
  refreshAd: (unit: AdSize) => Promise<TProps>;
  doIntervalRefresh?: boolean;
};

const AdContainerUnmemoized = <TProps extends AdSize>({
  id,
  width,
  height,
  sx,
  alignItems = 'center',
  justifyContent = 'center',
  borderRadius = '10px',
  findAdSize,
  AdInsert,
  refreshAd,
  doIntervalRefresh = true,
}: AdContainerProps<TProps>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dimensions = useParentDimensions(width, height, containerRef);

  const adSize = useMemo(() => {
    if (dimensions.width === 0 || dimensions.height === 0) {
      return undefined;
    }
    return findAdSize(dimensions);
  }, [dimensions, findAdSize]);

  const { spyAd } = useAdBlock();

  const [adProps, setAdProps] = useState<TProps | null>(null);

  const loadAd = useCallback(async () => {
    if (!adSize) return;

    let spying: string | undefined;

    try {
      const props = await refreshAd(adSize);
      setAdProps(props);
    } catch (error) {
      console.error('Error refreshing ad:', error);
      setAdProps(null);

      const errorMessage = String(
        !!error && typeof error === 'object' && 'message' in error
          ? error.message
          : error,
      );
      spying = errorMessage;
    }

    setTimeout(() => {
      spyAd(spying);
    }, 100);
  }, [adSize, refreshAd, spyAd]);

  useEffect(() => {
    loadAd();
  }, [loadAd]);

  const containerSx = useMemo(() => {
    return {
      width: width === '100%' ? '100%' : `${adSize?.width || width}px`,
      height: height === '100%' ? '100%' : `${adSize?.height || height}px`,
      alignItems,
      justifyContent,
      ...sx,
    };
  }, [
    adSize?.height,
    adSize?.width,
    width,
    height,
    alignItems,
    justifyContent,
    sx,
  ]);

  const innerSx = useMemo(() => {
    return {
      overflow: 'hidden',
      borderRadius,
      width: adSize?.width ? `${adSize.width}px` : '100%',
      height: adSize?.height ? `${adSize.height}px` : '100%',
    };
  }, [adSize?.width, adSize?.height, borderRadius]);

  const [trackingTarget, setTrackingTarget] = useState<HTMLDivElement | null>(
    null,
  );

  const adInsert = useMemo(() => {
    if (!adProps) {
      return undefined;
    }
    return (
      <div ref={setTrackingTarget}>
        <AdInsert {...adProps} />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdInsert, sortedHash(adProps || {})]);

  const isMobile = useMobile();
  const adDisplayMs = isMobile ? MOBILE_AD_DISPLAY_MS : DESKTOP_AD_DISPLAY_MS;

  const engagementStopwatchCallbacks = useMemo((): StopwatchCallbackMap => {
    return {
      [adDisplayMs]: doIntervalRefresh
        ? loadAd
        : (reset) => {
            reset();
          },
    };
  }, [adDisplayMs, doIntervalRefresh, loadAd]);

  const trackingProps = useMemo(() => {
    return {
      id,
      data: {
        adUnitId: adSize?.adUnitId,
      },
      target: trackingTarget,
      callbacks: engagementStopwatchCallbacks,
    };
  }, [adSize?.adUnitId, engagementStopwatchCallbacks, id, trackingTarget]);
  useEngagementTracking(trackingProps);

  return (
    <Stack sx={containerSx} ref={containerRef}>
      <Stack sx={innerSx}>{adInsert}</Stack>
    </Stack>
  );
};

export const AdContainer = memo(AdContainerUnmemoized);
