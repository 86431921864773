/* eslint-disable security/detect-object-injection */
import { FC, ReactNode, useMemo } from 'react';
import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import { DateString, EventKeyed } from '../../../contexts/EventsLazyContext';
import { TournamentCard } from '../../cards/tournament/TournamentCard';
import {
  EventsCalendarHeader,
  EventsCalendarHeaderProps,
} from '../headers/EventsCalendarHeader';
import { CalendarDailyProps } from '../catalogs/CalendarDaily';
import { Tournament } from '../../../../functions/src/types/firestore/Game/Tournament';
import { toDateString } from '../../../../functions/src/util/dates/Dates';
import type { Timestamp } from 'firebase-admin/firestore';
import { ConverterFactory } from '../../../../functions/src/util/firestore/ConverterFactory';
import { SearchAlgolia } from '../search/SearchAlgolia';
import { Hit } from '../../../../functions/src/types/Hit';
import { transformToEventKeyed } from '../../calendar/transformToEventsKeyed';
import { useTheme } from '@mui/material/styles';

export type EventHit<TTime = Timestamp> = Tournament<TTime> &
  Hit & {
    dateDay: TTime;
  };
// TODO: Introduce other events types here (like Giveaway)

export type RenderWrapper<
  THit extends EventHit<TTime>,
  TTime = Timestamp,
> = FC<{
  hit: THit;
  children: JSX.Element;
}>;

export type RenderCard<
  THit extends EventHit<TTime>,
  TTime = Timestamp,
> = FC<THit>;

export type EventsCalendarProps<THit extends EventHit | EventKeyed> =
  EventsCalendarHeaderProps & {
    CalendarDaily: FC<
      Pick<
        CalendarDailyProps,
        'dayToEvents' | 'onCalendarEndReached' | 'Extension' | 'query'
      >
    >;
    hits: THit[];
    onLoadMore: (direction?: 'forward' | 'backward') => void;
    Extension: CalendarDailyProps['Extension'];
    Wrapper?: RenderWrapper<EventHit<Date>, Date>;
    Card?: RenderCard<EventHit<Date>, Date>;
    query?: string;
  };

export const transformToEvent = <THit extends EventHit<Date>>(
  hit: THit,
  Card: RenderCard<EventHit<Date>, Date>,
  Wrapper?: RenderWrapper<EventHit<Date>, Date>,
) => {
  const { key, Node } = transformToEventKeyed(hit, Card, Wrapper);

  return {
    key,
    Node,
    dateDay: hit.dateDay,
  };
};

export const converter = ConverterFactory.buildDateConverter<EventHit<Date>>();

const EventsCalendarUnmemoized = <THit extends EventHit | EventKeyed>({
  CalendarDaily,
  hits,
  onLoadMore,
  Wrapper,
  Extension,
  query,
  Card = TournamentCard,
  ...headerProps
}: EventsCalendarProps<THit>) => {
  const theme = useTheme();

  const dayToEvents = useMemo(() => {
    return hits.reduce((prev, curr, index) => {
      if (!!curr && 'objectID' in curr) {
        const hitConverted = converter.convertData(curr);
        const dateKey = toDateString(new Date(hitConverted.date));

        if (!prev[String(dateKey)]) {
          prev[String(dateKey)] = [];
        }

        const eventKeyed = transformToEvent(hitConverted, Card, Wrapper);
        prev[String(dateKey)].push(eventKeyed);
      } else if (!!curr && 'Node' in curr && 'dateDay' in curr) {
        const referenceHitUnconverted =
          hits
            .slice(0, index)
            .reverse()
            .find((prevHit) => {
              return 'date' in prevHit;
            }) ||
          hits.slice(index + 1).find((nextHit) => {
            return 'date' in nextHit;
          });
        const referenceHit = referenceHitUnconverted
          ? converter.convertData(referenceHitUnconverted)
          : undefined;

        const referenceDate = referenceHit?.date || curr.dateDay;

        const dateKey = toDateString(new Date(referenceDate));
        if (!prev[String(dateKey)]) {
          prev[String(dateKey)] = [];
        }

        const eventKeyed: EventKeyed = {
          Node: curr.Node as ReactNode,
          key: curr.key,
          dateDay: curr.dateDay,
        };
        prev[String(dateKey)].push(eventKeyed);
      }

      return prev;
    }, {} as Record<DateString, EventKeyed[]>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hits, Card, Wrapper]);

  const Search = useMemo(() => {
    return <SearchAlgolia collapsable={false} placeholder="Search events..." />;
  }, []);

  return (
    <EventsCalendarHeader Search={Search} {...headerProps}>
      <Stack
        sx={{
          mx: 1,
          [theme.breakpoints.down(375)]: {
            mx: 0,
          },
          mb: 1,
          mt: 2,
          borderRadius: '5px',
          overflow: 'hidden',
        }}
      >
        <CalendarDaily
          dayToEvents={dayToEvents}
          onCalendarEndReached={onLoadMore}
          Extension={Extension}
          query={query}
        />
      </Stack>
    </EventsCalendarHeader>
  );
};

export const EventsCalendar = memo(EventsCalendarUnmemoized);
