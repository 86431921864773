import Stack from '@mui/material/Stack';
import { RoundStepper, RoundStepperProps } from '../RoundStepper';
import { useMobile } from 'src/hooks/useMobile';
import { Bracket, BracketProps } from './Bracket';
import { BracketLoser } from './BracketLoser';
import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { BracketLoserTitle } from './BracketLoserTitle';
import { Sponsors } from 'src/components/leaderboards/Sponsors';
import { BracketGrandFinal } from './BracketGrandFinal';
import { BracketRoundsKeys } from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { Tournament } from '../../../../functions/src/types/firestore/Game/Tournament';
import { Ad } from '../../ads/Ad';
import { BRACKET_AD } from '../../../../functions/src/util/ads/adIds';
import { LARGE_RECTANGLE } from '../../../../functions/src/util/ads/AdDimension';

export const SPONSORS_HEIGHT = 204;
export const SPONSORS_WIDTH = 358;
export const SPONSOR_TITLE_HEIGHT = 36;

export type BracketBoardProps = Omit<BracketProps, 'Match'> &
  Omit<RoundStepperProps, 'isMobile' | 'roundsCount'> &
  Pick<Tournament<Date>, 'sponsors'> & {
    activeRound?: number;
    activeRoundProgress?: number;
    variant: BracketRoundsKeys;
  };

export const BracketBoard = memo(function BracketBoardUnmemoized({
  rounds,
  activeRound,
  activeRoundProgress,
  sponsors,
  variant,
}: BracketBoardProps) {
  const isMobile = useMobile();
  const isLoserBracket = variant === 'bracketLoser';
  const isGrandFinal = variant === 'grandFinal';

  const sponsorOffset = useMemo(() => {
    switch (rounds.length) {
      case 1:
        return 80;
      case 3:
        return 28;
      case 4:
        return isLoserBracket ? 66 : 0;
      case 5:
        return 0;
      case 6:
        return 38;
      case 8:
        return 14;
      default:
        return 68;
    }
  }, [rounds.length, isLoserBracket]);

  const roundStepperOffset = useMemo(() => {
    switch (rounds.length) {
      case 2:
        return 8;
      case 4:
        return isLoserBracket && !isMobile ? 8 : 0;
      default:
        return 0;
    }
  }, [rounds.length, isMobile, isLoserBracket]);

  const bracketOffset = rounds.length <= 2 ? '5%' : '-100%';

  return (
    <>
      {isLoserBracket && <BracketLoserTitle sx={{ py: 4 }} />}
      {isMobile && (
        <Stack alignItems={'center'} justifyContent={'center'} pt={4}>
          <Ad id={BRACKET_AD} {...LARGE_RECTANGLE} />
        </Stack>
      )}
      {isMobile && !!sponsors?.length && !isLoserBracket && (
        <Stack sx={{ py: 4, justifyContent: 'center', alignItems: 'center' }}>
          <Sponsors
            titleVariant="h6"
            direction="column"
            sponsors={sponsors}
            sx={{
              height: SPONSORS_HEIGHT,
              width: SPONSORS_WIDTH,
            }}
          />
        </Stack>
      )}
      <Stack
        direction={isLoserBracket ? 'column-reverse' : 'column'}
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyItems="center"
        sx={{
          overflowX: 'auto',
          height: '100%',
          width: '100%',
          position: 'relative',
          '&::-webkit-scrollbar': { display: isMobile ? 'none' : undefined },
        }}
      >
        {!!sponsors?.length && !isMobile && (
          <Sponsors
            titleVariant="h6"
            direction="column"
            sponsors={sponsors}
            sx={{
              height: SPONSORS_HEIGHT,
              width: SPONSORS_WIDTH,
              zIndex: 100,
              position: 'absolute',
              transform: 'translateY(20%)',
              top: isLoserBracket ? 8 : 140,
            }}
          />
        )}
        <Stack
          sx={{
            width:
              isMobile && isGrandFinal
                ? 480
                : rounds.length === 1
                ? 228
                : (isMobile ? 208 : 296) *
                  (isLoserBracket && !isMobile
                    ? Math.ceil(rounds.length / 2) + 1
                    : rounds.length),
          }}
          pb={!isLoserBracket && !isMobile ? roundStepperOffset : 0}
          alignItems={'center'}
        >
          <RoundStepper
            {...{
              rounds,
              activeRound,
              activeRoundProgress,
              bracketVariant: variant,
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ p: 2, pt: !isMobile ? sponsorOffset : 0 }}
        >
          {isLoserBracket ? (
            <BracketLoser rounds={rounds} />
          ) : isGrandFinal ? (
            <BracketGrandFinal rounds={rounds} />
          ) : (
            <Bracket rounds={rounds} />
          )}
        </Stack>
        {!isMobile && (
          <Stack
            sx={{
              zIndex: 100,
              transform: `translateY(${bracketOffset})`,
            }}
          >
            <Ad id={BRACKET_AD} {...LARGE_RECTANGLE} />
          </Stack>
        )}
      </Stack>
    </>
  );
});
