import { KeyedNode, OrNode } from '../../../functions/src/types/Hit';
import { Ad, AdProps } from '../../components/ads/Ad';

export type InjectAdsProps<TElement> = AdProps & {
  elements: OrNode<TElement>[];
  adPositions: Set<number>;
};

export const injectAds = <TElement,>({
  elements,
  adPositions,
  id,
  ...containerProps
}: InjectAdsProps<TElement>) => {
  return elements.reduce(
    (accumulator: OrNode<TElement>[], element, index, array) => {
      if (adPositions.has(index)) {
        const adIndex = accumulator.filter(isAdapexAd).length;
        const uniqueKey = `ad-${adIndex}`;
        const adId = `${id}-${adIndex}`;
        const nextElement = array[index + 1] as { dateDay?: number };
        const prevElement = array[index - 1] as { dateDay?: number };

        const dateDay = prevElement?.dateDay || nextElement?.dateDay;

        const adNode = {
          key: uniqueKey,
          Node: <Ad key={uniqueKey} id={adId} {...containerProps} />,
          dateDay,
        };
        accumulator.push(adNode);
      }

      accumulator.push(element);

      return accumulator;
    },
    [] as OrNode<TElement>[],
  );
};

export function isAdapexAd(item: unknown): item is KeyedNode {
  return (
    !!item &&
    typeof item === 'object' &&
    'Node' in item &&
    !!item.Node &&
    typeof item.Node === 'object' &&
    'type' in item.Node &&
    item.Node.type === Ad
  );
}
