import { ChangeEvent, useCallback } from 'react';
import { memo } from '../../../../util/memo';
import { useSetMatchScreenshot } from '../../../../hooks/tournaments/useSetMatchScreenshot';
import UploadIcon from '@mui/icons-material/UploadRounded';
import { ImageUploadButton } from '../../../buttons/ImageUploadButton';
import { useActiveMatchRouter } from '../../../../contexts/ActiveMatchRouterContext';

export type ReplaceScreenshot = {
  screenshotUrl: string;
};

export const ReplaceScreenshot = memo(function ReplaceScreenshotUnmemoized({
  screenshotUrl,
}: ReplaceScreenshot) {
  const { sessionNumber: activeSessionIndex } = useActiveMatchRouter();
  const { replaceScreenshot } = useSetMatchScreenshot(activeSessionIndex);
  const replace = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      await replaceScreenshot(event, screenshotUrl);
    },
    [screenshotUrl, replaceScreenshot],
  );

  return <ImageUploadButton IconComponent={UploadIcon} onChange={replace} />;
});
