import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/CancelRounded';
import { SxProps, useTheme } from '@mui/material/styles';
import { keyframes } from '@emotion/react';
import { useState, useCallback, useMemo } from 'react';
import { memo } from '../util/memo';

export const DIALOG_MAX_WIDTH = 1200;

export const BOUNCE_REJECT_ANIMATION = keyframes`
  from, to { transform: scale(1) }
  50% { transform: scale(1.08) }
  `;

export type DialogCenteredProps = DialogProps & {
  innerSx?: SxProps;
  onClose?: () => void;
  contentSx?: SxProps;
  preventBackdropClick?: boolean;
  showCloseIcon?: boolean;
  PaperProps?: SxProps & Record<string, unknown>;
};

export const DialogCentered = memo(function DialogCenteredUnmemoized({
  children,
  innerSx,
  onClose,
  contentSx,
  showCloseIcon = true,
  preventBackdropClick = false,
  PaperProps = {},
  ...rest
}: DialogCenteredProps) {
  const theme = useTheme();
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  const closeDialog = useCallback(
    (_, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'backdropClick' && preventBackdropClick) {
        return setShouldAnimate(true);
      }
      onClose?.();
    },
    [onClose, preventBackdropClick],
  );

  const PaperPropsFull = useMemo(() => {
    return {
      sx: {
        animation: shouldAnimate ? `${BOUNCE_REJECT_ANIMATION} .4s` : 'none',
        width: '100%',
        maxWidth: `${DIALOG_MAX_WIDTH}px`,
        maxHeight: '100% !important',
        ...PaperProps,
      },
    };
  }, [PaperProps, shouldAnimate]);

  const unsetAnimate = useCallback(() => {
    setShouldAnimate(false);
  }, []);

  const CloseIcon = useMemo(() => {
    if (!showCloseIcon) {
      return undefined;
    }
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          height: '40px',
          mb: { xs: '-16px', md: '-4px' },
        }}
      >
        <IconButton onClick={onClose} sx={{ mr: '-12px', mt: '-8px' }}>
          <CancelIcon
            sx={{
              color: theme.palette.primary.mid,
            }}
          />
        </IconButton>
      </Box>
    );
  }, [onClose, showCloseIcon, theme.palette.primary.mid]);

  return (
    <Dialog
      {...rest}
      onClose={closeDialog}
      PaperProps={PaperPropsFull}
      onAnimationEnd={unsetAnimate}
      disableEscapeKeyDown={preventBackdropClick}
    >
      <DialogContent
        sx={{
          position: 'relative',
          ...contentSx,
        }}
      >
        <Box
          sx={{ p: { xs: '12px 16px 16px 16px', md: '16px 24px 24px 24px' } }}
        >
          {CloseIcon}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              ...innerSx,
            }}
          >
            {children}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
});
