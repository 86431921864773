import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { User } from '../../../../functions/src/types/firestore/User';
import { SearchDropdown } from '../catalogs/SearchDropdown';
import { AddTeammateListItem } from '../../cards/tournament/AddTeammateListItem';
import { withAdInjection } from '../../../util/ads/withAdInjections';
import { Hit, OrNode } from '../../../../functions/src/types/Hit';
import { renderHits } from '../../../util/algolia/renderHits';
import { SHORT_VERTICAL_AD_INJECTION_CONFIG } from '../../../util/ads/adInjectionConfigs';

const BUFFER_BEFORE_FETCH = 4;

export type UserDropdownSearchProps = {
  hits: OrNode<Hit<User<Date>>>[];
  onLoadMore: () => void;
};

const UserDropdownSearchUnmemoized = ({
  hits,
  onLoadMore,
}: UserDropdownSearchProps) => {
  const Users = useMemo(() => {
    return renderHits(hits, (hit) => {
      return <AddTeammateListItem {...hit} />;
    });
  }, [hits]);

  return (
    <SearchDropdown
      Content={Users}
      onNearEnd={onLoadMore}
      buffer={BUFFER_BEFORE_FETCH}
      placeholder={'Search for a teammate...'}
    />
  );
};

export const UserDropdownSearch = memo(UserDropdownSearchUnmemoized);

export const useUserDropdownSearchAds = (containerId: string) => {
  return useMemo(() => {
    return withAdInjection({
      WrappedComponent: UserDropdownSearch,
      id: containerId,
      ...SHORT_VERTICAL_AD_INJECTION_CONFIG,
    });
  }, [containerId]);
};
