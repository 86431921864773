import { useMemo } from 'react';
import { useRouterState } from './useRouterState';
import { GROUP_IDS } from '../../util/routing/groupIds';

export const useGroupId = () => {
  const routerStates = GROUP_IDS.map(({ key, location }) => {
    {
      // This is okay because the order of a global static
      // constant array (GROUP_IDS) is guaranteed to be stable
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useRouterState({ key, location });
    }
  });

  return useMemo(() => {
    const definedValues = routerStates
      .map(([value], index) => {
        if (value === undefined) return undefined;

        const { prepare } = GROUP_IDS[Number(index)];
        return prepare ? prepare(value) : value;
      })
      .filter((value): value is string => {
        return value !== undefined;
      });

    return definedValues.join('-');
  }, []);
};
