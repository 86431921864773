import { Stack } from '@mui/system';
import { FC, useCallback } from 'react';
import { AD_UNIT_IDS } from '../../../functions/src/util/ads/adapexAdUnits';
import { findLargestFit } from '../../../functions/src/util/ads/findLargestFit';
import { Adapex } from '../../util/ads/Adapex';
import { AdSize, AdContainer } from './AdContainer';
import { memo } from '../../util/memo';
import { AdProps } from './Ad';

const AdInsert = memo(({ adUnitId }: { adUnitId: string }) => {
  return (
    <Stack
      data-aaad="true"
      data-aa-lazy-loaded="true"
      data-aa-adunit={adUnitId}
    />
  );
});

export const AdapexAdUnmemoized: FC<AdProps> = (props) => {
  const findAdSize = useCallback(
    (dimensions: { width: number; height: number }) => {
      return findLargestFit(AD_UNIT_IDS, dimensions.width, dimensions.height);
    },
    [],
  );

  const refreshAd = useCallback(async (unit: AdSize) => {
    await Adapex.processAdsOnPage();

    return unit;
  }, []);

  return (
    <AdContainer
      {...props}
      findAdSize={findAdSize}
      AdInsert={AdInsert}
      refreshAd={refreshAd}
      doIntervalRefresh={false}
    />
  );
};

export const AdapexAd = memo(AdapexAdUnmemoized);
