import { useRef, useEffect } from 'react';
import {
  useIntersectionObserver,
  ExtendedIntersectionObserverOptions,
} from './useIntersectionObserver';

export const DEFAULT_INTERSECTION_OPTIONS: ExtendedIntersectionObserverOptions =
  {
    root: 'nearest-scrollable',
    threshold: 1,
  } as const;

export type UseNearEndObserverOptions = {
  onNearEnd?: () => void;
  intersectionOptions?: ExtendedIntersectionObserverOptions;
};

export function useNearEndObserver({
  onNearEnd,
  intersectionOptions = DEFAULT_INTERSECTION_OPTIONS,
}: UseNearEndObserverOptions = {}) {
  const nearEndRef = useRef<HTMLDivElement>(null);
  const intersection = useIntersectionObserver(nearEndRef, intersectionOptions);

  useEffect(() => {
    if (intersection?.isIntersecting) {
      onNearEnd?.();
    }
  }, [intersection?.isIntersecting, onNearEnd]);

  return nearEndRef;
}
