import '../util/ads/detectAdBlock';
import '../styles/global.css';
import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { CurrencyProvider } from '../contexts/CurrencyContext';
import defaultTheme from '../styles/theme';
import createEmotionCache from '../util/createEmotionCache';
import { AuthProvider } from '../contexts/AuthContext';
// import { TwitchAuthProvider } from 'src/contexts/TwitchAuthContext';
import { GlobalComponentsProvider } from 'src/contexts/GlobalComponentsContext';
import { ServerTimeProvider } from 'src/contexts/useServerTime';
import { LocationProvider } from 'src/contexts/useLocation';
import { PageLoadingProvider } from 'src/contexts/PageLoadingContext';
import { MaintenanceMode } from 'src/components/MaintenanceMode';
import { useUserTimezone } from 'src/hooks/time/useUserTimezone';
import { useRegisterServiceWorker } from 'src/hooks/firebase/useRegisterServiceWorker';
import { MainLayout } from '../components/MainLayout';
import { useDatadog } from '../hooks/useDatadog';
import { Web3Provider } from '../contexts/web3/Web3Context';
import { SignatureRequestProvider } from '../contexts/web3/SignatureRequestContext';
import { ProgressionProvider } from '../contexts/ProgressionContext';
import { IndexingHitsProvider } from '../contexts/algolia/IndexingHitsContext';
import { UserStatusProvider } from '../contexts/UserStatusContext';
import Head from 'next/head';
import { UnreadMessageCountProvider } from '../contexts/UnreadMessageCountContext';
import { StreamProvider } from '../contexts/get-stream/StreamContext';
import { UrlModificationsProvider } from '../contexts/routing/UrlModificationsContext';
import { NotificationCountProvider } from '../contexts/NotificationCountContext';
import { ActiveChannelGroupProvider } from '../contexts/ActiveChannelGroupContext';
import { RealtimeChannelGroupsProvider } from '../contexts/RealtimeChannelGroupContext';
import { SessionStorageProvider } from '../contexts/SessionStorageContext';
import { LocalStorageProvider } from '../contexts/LocalStorage';
import { Analytics } from '@vercel/analytics/react';
import { OptimisticValueProvider } from '../contexts/OptimisticValueContext';
import { AdBlockProvider } from '../contexts/AdBlockContext';
import { OnlineProvider } from '../contexts/OnlineContext';
import { GlobalIntervalProvider } from '../contexts/GlobalIntervalContext';
import { VerifyEmailProvider } from '../contexts/VerifyEmailContext';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

type BlumintAppProps<T = Record<string, unknown>> = AppProps<T> & {
  emotionCache?: EmotionCache;
};

export const MIN_VIEWPORT_WIDTH = 654;

export default function BlumintApp(props: BlumintAppProps) {
  useRegisterServiceWorker();
  useUserTimezone();
  useDatadog();

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  return (
    <OptimisticValueProvider>
      <LocalStorageProvider>
        <UrlModificationsProvider>
          <Web3Provider>
            <LocationProvider>
              <ServerTimeProvider>
                {/* <TwitchAuthProvider> */}
                <ThemeProvider theme={defaultTheme}>
                  <CssBaseline />
                  <MaintenanceMode>
                    <PageLoadingProvider>
                      <GlobalIntervalProvider>
                        <AuthProvider>
                          <StreamProvider>
                            <SessionStorageProvider>
                              <OnlineProvider>
                                <AdBlockProvider>
                                  <RealtimeChannelGroupsProvider>
                                    <CurrencyProvider>
                                      <CacheProvider value={emotionCache}>
                                        <UnreadMessageCountProvider>
                                          <ActiveChannelGroupProvider>
                                            <UserStatusProvider>
                                              <Head>
                                                <title>BluMint</title>
                                                <meta
                                                  name="google-adsense-account"
                                                  content="ca-pub-4740369050630888"
                                                />
                                                <meta
                                                  name="viewport"
                                                  content="width=device-width, user-scalable=0"
                                                />
                                                <script
                                                  src="https://cdn.adapex.io/hb/aaw.blumint.js"
                                                  async
                                                />
                                                <script async>
                                                  {
                                                    '!function(){var e=document.createElement("script"),t=document.getElementsByTagName("script")[0],a="https://cmp.inmobi.com".concat("/choice/","WFJsXCa9VD158","/","blumint.com","/choice.js?tag_version=V3"),i=0;e.async=!0,e.type="text/javascript",e.src=a,t.parentNode.insertBefore(e,t),!function e(){for(var t,a="__tcfapiLocator",i=[],s=window;s;){try{if(s.frames[a]){t=s;break}}catch(n){}if(s===window.top)break;s=s.parent}t||(!function e(){var t=s.document,i=!!s.frames[a];if(!i){if(t.body){var n=t.createElement("iframe");n.style.cssText="display:none",n.name=a,t.body.appendChild(n)}else setTimeout(e,5)}return!i}(),s.__tcfapi=function e(){var t,a=arguments;if(!a.length)return i;if("setGdprApplies"===a[0])a.length>3&&2===a[2]&&"boolean"==typeof a[3]&&(t=a[3],"function"==typeof a[2]&&a[2]("set",!0));else if("ping"===a[0]){var s={gdprApplies:t,cmpLoaded:!1,cmpStatus:"stub"};"function"==typeof a[2]&&a[2](s)}else"init"===a[0]&&"object"==typeof a[3]&&(a[3]=Object.assign(a[3],{tag_version:"V3"})),i.push(a)},s.addEventListener("message",function e(t){var a="string"==typeof t.data,i={};try{i=a?JSON.parse(t.data):t.data}catch(s){}var n=i.__tcfapiCall;n&&window.__tcfapi(n.command,n.version,function(e,i){var s={__tcfapiReturn:{returnValue:e,success:i,callId:n.callId}};a&&(s=JSON.stringify(s)),t&&t.source&&t.source.postMessage&&t.source.postMessage(s,"*")},n.parameter)},!1))}();var s=function(){var e=arguments;typeof window.__uspapi!==s&&setTimeout(function(){void 0!==window.__uspapi&&window.__uspapi.apply(window.__uspapi,e)},500)},n=function(){i++,window.__uspapi===s&&i<3?console.warn("USP is not accessible"):clearInterval(p)};if(void 0===window.__uspapi){window.__uspapi=s;var p=setInterval(n,6e3)}}();'
                                                  }
                                                </script>
                                              </Head>
                                              <IndexingHitsProvider>
                                                <NotificationCountProvider>
                                                  <GlobalComponentsProvider>
                                                    <ProgressionProvider>
                                                      <SignatureRequestProvider>
                                                        <VerifyEmailProvider>
                                                          <MainLayout>
                                                            <Component
                                                              {...pageProps}
                                                            />
                                                            <Analytics />
                                                          </MainLayout>
                                                        </VerifyEmailProvider>
                                                      </SignatureRequestProvider>
                                                    </ProgressionProvider>
                                                  </GlobalComponentsProvider>
                                                </NotificationCountProvider>
                                              </IndexingHitsProvider>
                                            </UserStatusProvider>
                                          </ActiveChannelGroupProvider>
                                        </UnreadMessageCountProvider>
                                      </CacheProvider>
                                    </CurrencyProvider>
                                  </RealtimeChannelGroupsProvider>
                                </AdBlockProvider>
                              </OnlineProvider>
                            </SessionStorageProvider>
                          </StreamProvider>
                        </AuthProvider>
                      </GlobalIntervalProvider>
                    </PageLoadingProvider>
                  </MaintenanceMode>
                </ThemeProvider>
                {/* </TwitchAuthProvider> */}
              </ServerTimeProvider>
            </LocationProvider>
          </Web3Provider>
        </UrlModificationsProvider>
      </LocalStorageProvider>
    </OptimisticValueProvider>
  );
}
