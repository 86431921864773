import AvatarGroup, { AvatarGroupProps } from '@mui/material/AvatarGroup';
import { AvatarNext, AvatarNextProps } from './AvatarNext';
import { SxProps } from '@mui/material/styles';
import { useOnlineStatus } from '../hooks/useOnlineStatus';
import { Status } from './Status';
import { OptionalWrapper } from './OptionalWrapper';
import { memo } from '../util/memo';

export type AvatarGroupNextProps = AvatarGroupProps & {
  imgUrls: string[];
  borderColor?: string;
  avatarProps?: AvatarNextProps;
  avatarGroupProps?: AvatarNextProps;
  avatarSx?: SxProps;
  badgeSx?: SxProps;
  userId?: string;
  showStatus?: boolean;
};

const AvatarGroupNextUnmemoized = (props: AvatarGroupNextProps) => {
  const {
    imgUrls,
    borderColor,
    userId,
    showStatus = false,
    avatarProps = { height: { xs: 44, md: 56 }, width: { xs: 44, md: 56 } },
    avatarGroupProps = {
      height: { xs: 30, md: 42 },
      width: { xs: 30, md: 42 },
    },
    badgeSx = {},
    avatarSx = {},
    sx,
    ...rest
  } = props;

  const { onlineStatus } = useOnlineStatus([userId || '']);
  const onlineStatusEnum = onlineStatus[String(userId)] ? 'online' : 'offline';

  return (
    <>
      {imgUrls.length > 1 ? (
        <AvatarGroup
          {...rest}
          sx={{
            '.MuiAvatar-root:first-of-type': {
              ml: { xs: '-24px', md: '-32px' },
            },
            '.MuiAvatar-root:last-of-type': {
              mt: { xs: '12px', md: '14px' },
            },
            '.MuiAvatar-root:nth-of-type(1)': {
              mt: { xs: '-8px', md: '-10px' },
              mr: { xs: '-4px', md: '-8px' },
              height: { xs: 30, md: 42 },
              width: { xs: 30, md: 42 },
            },
            pt: 2,
            ...sx,
          }}
        >
          {imgUrls.map((imgUrl) => {
            return (
              <AvatarNext
                key={imgUrl}
                src={imgUrl}
                {...avatarGroupProps}
                sx={{ ...avatarSx }}
              />
            );
          })}
        </AvatarGroup>
      ) : (
        <OptionalWrapper
          Wrapper={<Status kind="Badge" statusEnum={onlineStatusEnum} />}
          doWrap={showStatus}
          sx={badgeSx}
        >
          <AvatarNext
            key={imgUrls[0]}
            src={imgUrls[0]}
            {...avatarProps}
            sx={{
              border: !!borderColor ? `2px solid ${borderColor}` : 'none',
              ...avatarSx,
            }}
          />
        </OptionalWrapper>
      )}
    </>
  );
};
export const AvatarGroupNext = memo(AvatarGroupNextUnmemoized);
