import {
  Fragment,
  ReactNode,
  memo,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { GradientButton } from '../gradients/GradientButton';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { useMobile } from '../../hooks/useMobile';
import { OptionalWrapper } from '../OptionalWrapper';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

export const END_ICON_WIDTH = '22px';
export const END_ICON_HEIGHT = '22px';

export type ShareButtonInfoProps = {
  children: ReactNode;
  tooltip?: ReactNode;
};

export const ShareButtonInfo = memo(function ShareButtonInfoUnmemoized({
  children,
  tooltip,
}: ShareButtonInfoProps) {
  const isMobile = useMobile();
  const [isClicked, setIsClicked] = useState(false);

  const markClicked = useCallback(() => {
    setIsClicked(true);
  }, []);

  const shareButton = useMemo(() => {
    return (
      <OptionalWrapper
        doWrap={!isMobile && !!tooltip && !isClicked}
        Wrapper={
          <Tooltip arrow={true} title={tooltip}>
            <Box width={'100%'}></Box>
          </Tooltip>
        }
      >
        <GradientButton
          endIcon={ShareOutlinedIcon}
          variant="outlined"
          onClick={markClicked}
        >
          {children}
        </GradientButton>
      </OptionalWrapper>
    );
  }, [children, isMobile, tooltip, isClicked, markClicked]);

  return <Fragment>{shareButton}</Fragment>;
});
