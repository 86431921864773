import {
  useMemo,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  ComponentType,
} from 'react';
import { ReactGlider, ReactGliderProps } from '../../ReactGlider';
import Box from '@mui/material/Box';
import { DateString, EventKeyed } from '../../../contexts/EventsLazyContext';
import { Required } from 'utility-types';
import { CalendarDayTodaylight } from './CalendarDayTodaylight';
import Stack from '@mui/material/Stack';
import { memo } from '../../../util/memo';
import { UniversalAppStatus } from '../../error/UniversalAppStatus';
import { useNumVisibleColumns } from '../../../hooks/calendar/useNumVisibleColumns';
import Fade from '@mui/material/Fade';
import { toLocalMidnightDate } from '../../../../functions/src/util/date/paramsUtc';
import { CALENDAR_DAY_WIDTH } from './CalendarDay';

export const CALENDAR_COLUMN_WIDTH = CALENDAR_DAY_WIDTH + 8;

export type GliderCalendarProps = Omit<
  Required<ReactGliderProps, 'gliderRef'>,
  'hasArrows' | 'duration' | 'itemWidth' | 'children' | 'cursor'
> & {
  dayToEvents: Record<DateString, EventKeyed[]>;
  height: string;
  initialDate?: Date;
  placeholderText?: string;
  Extension: ComponentType<{ date: Date }>;
  query?: string;
};

export const GliderCalendarUnmemoized: React.FC<GliderCalendarProps> = ({
  dayToEvents,
  height,
  initialDate,
  gliderRef,
  placeholderText,
  Extension,
  query,
  ...props
}) => {
  const { firstEventRef } = useNumVisibleColumns();

  const sortedDayToEvents = useMemo(() => {
    return Object.entries(dayToEvents).sort(([dateA], [dateB]) => {
      return new Date(dateA).getTime() - new Date(dateB).getTime();
    });
  }, [dayToEvents]);

  const [prevDayToEvents, setPrevDayToEvents] = useState(sortedDayToEvents);

  useEffect(() => {
    const prevDays = prevDayToEvents.map(([date, _]) => {
      return date;
    });
    const currentDays = sortedDayToEvents.map(([date, _]) => {
      return date;
    });

    if (prevDays[0] !== currentDays[0]) {
      const newDaysCount = currentDays.indexOf(prevDays[0]);
      if (newDaysCount > 0) {
        gliderRef.current?.shiftCursor(newDaysCount);
      }
    }

    setPrevDayToEvents(sortedDayToEvents);
  }, [gliderRef, prevDayToEvents, sortedDayToEvents]);

  const findIndexOf = useCallback(
    (targetDate: Date) => {
      const targetTime = targetDate.getTime();

      return sortedDayToEvents
        .map(([dateString], index) => {
          const date = new Date(dateString);
          const diff = Math.abs(date.getTime() - targetTime);

          return { diff, index };
        })
        .reduce(
          (min, curr) => {
            return curr.diff < min.diff ? curr : min;
          },
          {
            diff: Infinity,
            index: -1,
          },
        ).index;
    },
    [sortedDayToEvents],
  );

  const cursorInitial = useMemo(() => {
    if (sortedDayToEvents.length === 0) {
      return 'no-events';
    }
    return initialDate ? findIndexOf(initialDate) : 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate, sortedDayToEvents.length]);

  const [extensionCache, setExtensionCache] = useState<
    Record<string, { node: ReactNode; intersected: boolean }>
  >({});

  useEffect(() => {
    setExtensionCache({});
  }, [query]);

  const intersect = useCallback(
    (dateString: string) => {
      setExtensionCache((prevCache) => {
        if (
          !prevCache[String(dateString)] ||
          !prevCache[String(dateString)].intersected
        ) {
          const localMidnight = toLocalMidnightDate(dateString);

          const extension = <Extension date={localMidnight} />;
          return {
            ...prevCache,
            [dateString]: { node: extension, intersected: true },
          };
        }
        return prevCache;
      });
    },
    //extensionCache is here to ensure the Extension gets reset when the search query changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Extension, extensionCache],
  );

  const memoizedOnIntersect = useCallback(
    (dateString: string) => {
      return () => {
        intersect(dateString);
      };
    },
    [intersect],
  );

  const Columns = useMemo(() => {
    return sortedDayToEvents.map(([dateString, eventsOfDate], index) => {
      const extensionData = extensionCache[String(dateString)] || {
        node: undefined,
        intersected: false,
      };
      return (
        <Box key={dateString} ref={index === 0 ? firstEventRef : undefined}>
          <CalendarDayTodaylight
            dateString={dateString}
            eventsOfDate={eventsOfDate}
            height={height}
            extension={extensionData.node}
            onIntersect={memoizedOnIntersect(dateString)}
            intersected={extensionData.intersected}
          />
        </Box>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extensionCache, firstEventRef, sortedDayToEvents]);

  const glider = useMemo(() => {
    return (
      cursorInitial !== 'no-events' && (
        <ReactGlider
          gliderRef={gliderRef}
          itemWidth={CALENDAR_COLUMN_WIDTH}
          hasArrows={false}
          duration={0}
          cursor={cursorInitial}
          {...props}
        >
          {Columns}
        </ReactGlider>
      )
    );
  }, [Columns, cursorInitial, gliderRef, props]);

  return (
    <Stack height={height}>
      {placeholderText && cursorInitial === 'no-events' && (
        <Fade in easing="ease-in-out">
          <div>
            <UniversalAppStatus
              imgUrl="/assets/images/mascots/mascot-crying.png"
              title="NO EVENTS FOUND"
              description={placeholderText}
              size={300}
              subText={false}
              showButton={false}
              prefetchImage={true}
            />
          </div>
        </Fade>
      )}
      {glider}
    </Stack>
  );
};

export const GliderCalendar = memo(GliderCalendarUnmemoized);
