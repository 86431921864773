import { useCallback, useContext } from 'react';
import { GradientButton } from '../../../gradients/GradientButton';
import { TournamentContext } from '../../../../contexts/docs/TournamentContext';
import { useMatch } from '../MatchProvider';
import ChatIcon from '@mui/icons-material/ChatRounded';
import { useActiveTournamentChannel } from '../../../../hooks/messaging/useActiveTournamentChannel';
import { useActiveMatchRouter } from '../../../../contexts/ActiveMatchRouterContext';
import { memo } from '../../../../util/memo';

const OpenMatchLobbyButtonUnmemoized = () => {
  const { id: tournamentId } = useContext(TournamentContext);
  const { openMatchChannel } = useActiveTournamentChannel(tournamentId);
  const { close } = useActiveMatchRouter();
  const { id: matchId } = useMatch();

  const open = useCallback(() => {
    openMatchChannel(matchId);
    close();
  }, [openMatchChannel, matchId, close]);

  return (
    <GradientButton
      startIcon={ChatIcon}
      sx={{ borderRadius: '50px', mb: 4, width: '100%', height: '40px' }}
      onClick={open}
    >
      OPEN MATCH LOBBY
    </GradientButton>
  );
};

export const OpenMatchLobbyButton = memo(OpenMatchLobbyButtonUnmemoized);
