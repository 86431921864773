import { MutableRefObject, useCallback, useMemo, useRef } from 'react';
import { memo } from '../../../util/memo';
import { useAuth } from '../../../contexts/AuthContext';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { ImageOptimized } from '../../image/ImageOptimized';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import Typography from '@mui/material/Typography';
import AddRounded from '@mui/icons-material/AddRounded';
import SwapVertIcon from '@mui/icons-material/SwapVertRounded';

export type ImageInputProps = {
  imageProperties: {
    width: number;
    height: number;
  };
  value: string;
  onChange: (value: string) => Promise<void>;
  label?: string;
  required: boolean;
  storagePath?: string;
};

export const ImageInput = memo(function ImageInputUnmemoized({
  imageProperties,
  value,
  onChange,
  label,
  required,
  storagePath,
}: ImageInputProps) {
  const { uid } = useAuth();
  const theme = useTheme();
  const UploadIcon = useMemo(() => {
    return value ? SwapVertIcon : AddRounded;
  }, [value]);
  const fileInput = useRef<HTMLInputElement | undefined>();

  const showError = useMemo(() => {
    return required && !value;
  }, [required, value]);

  const upload = useCallback(
    async (event) => {
      if (!storagePath || !uid) return;
      const { currentTarget } = event;
      if (!currentTarget) return;
      const { files } = currentTarget;
      if (!files?.length) return;

      const [fileForUpload] = files;
      try {
        const { uploadImage } = await import('../../../util/uploadImage');
        const url = await uploadImage({
          file: fileForUpload,
          storagePath,
        });
        await onChange(url);
      } catch (error) {
        console.error(error);
      }
      currentTarget.value = '';
    },
    [onChange, storagePath, uid],
  );

  return (
    <Stack gap={4}>
      <Stack
        sx={{
          ...theme.panels[1],
          padding: 0,
          position: 'relative',
          width: '100%',
          'aspect-ratio': `${imageProperties.width / imageProperties.height}`,
          border: `2px dashed ${
            showError ? theme.palette.error.main : theme.palette.primary.dark
          }`,
        }}
        direction={'column'}
        alignItems="center"
        justifyContent="center"
        gap={4}
      >
        <ImageOptimized
          src={value}
          layout="fill"
          style={{ opacity: 0.2 }}
          objectFit="cover"
          objectPosition="center"
        />
        <Stack
          direction="column"
          sx={{
            justifyContent: 'center',
            position: 'relative',
            width: imageProperties.width,
            height: imageProperties.height,
          }}
        >
          <Stack
            sx={{
              position: 'absolute',
              width: '100%',
              textAlign: 'center',
              alignSelf: 'center',
            }}
          >
            <GradientIconButton
              sxOuter={{ width: 75, height: 75, alignSelf: 'center' }}
              sx={{
                width: 75,
                height: 75,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              IconComponent={UploadIcon}
              onClick={() => {
                return fileInput.current && fileInput.current.click();
              }}
              gradientColor={
                showError ? 'error.horizontal' : 'primary.horizontal'
              }
            />

            <input
              ref={fileInput as MutableRefObject<HTMLInputElement>}
              type="file"
              accept={'image/*'}
              style={{ display: 'none' }}
              onChange={(e) => {
                return upload(e);
              }}
            />

            <Typography
              color="text.secondary"
              textTransform="uppercase"
              fontWeight={600}
            >
              {label}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
});
