import { AdUnitMap } from './AspectRatio';

export const AD_UNIT_IDS_STICKY: AdUnitMap = {
  '320x100': '/22181265/blumint_sticky_footer',
  '300x100': '/22181265/blumint_sticky_footer',
  '320x50': '/22181265/blumint_sticky_footer',
  '300x50': '/22181265/blumint_sticky_footer',
  '120x600': '/22181265/blumint_sticky_leftrail',
} as const;

export const AD_UNIT_IDS: AdUnitMap = {
  '300x250': '/22181265/blumint_300v_1',
  '336x280': '/22181265/blumint_300v_1',
  '320x100': '/22181265/blumint_320v_1',
  '300x100': '/22181265/blumint_320v_1',
  '320x50': '/22181265/blumint_320v_1',
  '300x50': '/22181265/blumint_320v_1',
  '728x90': '/22181265/blumint_728v_1',
  '970x90': '/22181265/blumint_970v_1',
  '120x600': '/22181265/blumint_sticky_leftrail',
} as const;
