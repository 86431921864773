import { FC, useContext } from 'react';
import { memo } from '../../../util/memo';
import { Box, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/CircleRounded';
import { useTheme } from '@mui/material/styles';
import { useDateFormatter } from 'src/hooks/useDateFormatter';
import { TournamentContext } from 'src/contexts/docs/TournamentContext';
import { NUMERIC_TIMEZONE } from 'src/util/dates/presets/times';
import { findIntermissionAround } from '../../../util/findIntermissionAround';

const TournamentCardPhaseUnmemoized: FC = () => {
  const { phase, date, endDate, readyTime, intermissions, skipCheckIn } =
    useContext(TournamentContext);

  const theme = useTheme();

  const format = useDateFormatter({
    dateFormat: false,
    timeFormat: NUMERIC_TIMEZONE,
    separator: ' ',
  });

  const currentIntermission = intermissions
    ? findIntermissionAround(intermissions)
    : undefined;

  const getPhaseTextAndTime = () => {
    if (currentIntermission) {
      const { end } = currentIntermission;
      return { timePrefix: 'INTERMISSION UNTIL', time: end };
    }

    switch (phase) {
      case 'ready':
      case 'review':
      case 'unpublished':
        return { timePrefix: 'STARTS AT', time: date };
      case 'live':
        return { timePrefix: 'LIVE SINCE', time: date };
      case 'finished':
        return { timePrefix: 'ENDED AT', time: endDate };
      default:
        return skipCheckIn
          ? { timePrefix: 'STARTS AT', time: date }
          : { timePrefix: 'CHECK-IN BY', time: readyTime };
    }
  };

  const { timePrefix, time } = getPhaseTextAndTime();

  return (
    <Box
      borderRadius="4px 7px 7px 4px !important;"
      padding={1}
      sx={{
        background: theme.palette.background.elevation[15],
      }}
    >
      <Typography
        sx={{
          fontWeight: '600',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          textTransform: 'uppercase',
          color: theme.palette.text.secondary,
        }}
      >
        {phase === 'live' && (
          <CircleIcon
            sx={{
              fontSize: '1rem',
              color: theme.palette.error.main,
              verticalAlign: '-2px',
              marginRight: 1,
            }}
          />
        )}
        {`${
          ['unpublished', 'review'].includes(phase)
            ? `(${phase.toUpperCase()}) `
            : ''
        }${timePrefix}: ${format(time)}`}
      </Typography>
    </Box>
  );
};

export const TournamentCardPhase = memo(TournamentCardPhaseUnmemoized);
