import { ReactNode, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { VerticalCarousel } from '../catalogs/VerticalCarousel';
import { AccordionCompetitor } from '../../tournaments/participants/accordion-competitor/AccordionCompetitor';
import { teamsParser } from '../../../util/tournaments/teamParser';
import { Team } from '../../../../functions/src/types/firestore/Game/Tournament/Guestlist';
import { Hit, OrNode } from '../../../../functions/src/types/Hit';
import { withAdInjection } from '../../../util/ads/withAdInjections';
import { renderHits } from '../../../util/algolia/renderHits';
import { SHORT_VERTICAL_AD_INJECTION_CONFIG } from '../../../util/ads/adInjectionConfigs';

export type TeamsCarouselWrapperProps = {
  hits: OrNode<Hit<Team<Date>>>[];
  onLoadMore: () => void;
  Header: ReactNode;
  maxTeamSize: number;
};

const TeamsCarouselWrapperUnmemoized = ({
  hits,
  onLoadMore,
  Header,
  maxTeamSize,
}: TeamsCarouselWrapperProps) => {
  const Participants = useMemo(() => {
    return renderHits(hits, (hit) => {
      const props = teamsParser(hit, maxTeamSize);
      return <AccordionCompetitor {...props} />;
    });
  }, [hits, maxTeamSize]);

  return (
    <VerticalCarousel
      Header={Header}
      Content={Participants}
      onNearEnd={onLoadMore}
      buffer={4}
      share={false}
      showDivider={false}
      background={8}
      containerSx={{ p: { xs: 2, md: 4 } }}
    />
  );
};

export const TeamsCarouselWrapper = memo(TeamsCarouselWrapperUnmemoized);

export const useTeamsCarouselWrapperAds = (containerId: string) => {
  return useMemo(() => {
    return withAdInjection({
      WrappedComponent: TeamsCarouselWrapper,
      id: containerId,
      ...SHORT_VERTICAL_AD_INJECTION_CONFIG,
      sx: { py: 2 },
    });
  }, [containerId]);
};
